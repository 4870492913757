.udug-app {
  display: flex;
}

.udug-app-content {
  max-width: 60rem;
  padding: var(--space-extra-large);
}

.menu {
  position: fixed;
  max-width: 26rem;
  width: 100%;
}
