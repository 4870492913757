.expandable_report_row:hover {
  cursor: pointer;
}

.report_entry_table-wrapper {
  padding-top: '0.5rem';
}

.report_entry_table-first_column {
  padding-left: '4.5rem';
}
